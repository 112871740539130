<template>
	<div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
		<Navigation />
		<media_screen path_arr="path_array2" :category_id="7" @screen_confirm="pageChange(1)"
			@reset_screen="pageChange(1)" />
		<table class="table_ShortVideo">
			<thead>
				<td>
					<input :disabled="tableData.length == 0" type="checkbox" v-model="Allcheck" @click="checkSwitch()"
						label="" v-show="tableData.length != 0" />
				</td>
				<td>所属平台</td>
				<td>媒介名称</td>
				<td>短视频号</td>
				<td>价格</td>
				<td>行业类型</td>
				<td>所属区域</td>
				<td>粉丝数</td>
				<td>受众性别</td>
				<td>受众年龄</td>
				<td>可发内链</td>
				<td>可发联系方式</td>
				<td>备注(仅供参考)</td>
				<td>操作</td>
			</thead>
			<tbody>
				<tr v-for="(item, index) in tableData" :key="index">
					<td>
						<input type="checkbox" v-model="item.check" @click="onPitch(index)" label="" />
					</td>
					<td>{{ item.portal_title }}</td>
					<td>
						<td_remark :text="item.title" />
					</td>
					<td>
						<td_remark :text="item.account" />
					</td>
					<td>￥{{ item.price }}</td>
					<td>{{ item.industry_classify_title }}</td>
					<td>{{ item.area_title }}</td>
					<td>{{ item.fans_num }}</td>
					<td>{{ item.audience_sex == 0 ? '不限' : item.audience_sex == 1 ? '女性粉丝偏多' :
		item.audience_sex == 2 ? '男性粉丝偏多' : '男女粉丝均衡' }}</td>
					<td>{{ item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' :
		item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 0 ? '25-34岁偏多' :
			item.audience_age == 0 ? '35-44岁偏多' : '44岁以上偏多' }}</td>
					<td :style="{ color: item.is_pub_link == 1 ? '#55C964' : '#FF0000' }">
						{{ item.is_pub_link == 0 ? '否' : '是' }}
					</td>
					<td :style="{ color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000' }">
						{{ item.is_pub_contact == 0 ? '否' : '是' }}
					</td>
					<td>
						<td_remark :text="item.remark" />
					</td>
					<td>
						<div class="collect_box flex"
							:style="{ 'justify-content': user_info ? 'space-between' : 'center' }">
							<p @click.stop="jumpLink(item)">查看案例</p>
							<!-- v-if="item.collect == 0"  -->
							<p v-if="item.is_collect == 0 && user_info" class="collect" @click.stop="collectGet(item)">
								收藏</p>
							<!-- v-if="item.collect == 1" -->
							<p v-if="item.is_collect == 1 && user_info" class="collect2"
								@click.stop="collectClose(item)">已收藏
							</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- 分页器 -->
		<div class="paging_box">
			<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
		</div>
		<!-- 购物车 -->
		<div v-show="shopShow == false" class="shopping_car">
			<div class="car" @click.stop="shopShow = true">
				<p>{{ CarList.length }}</p>
			</div>
		</div>
		<div :class="shopShow ? 'shop_box' : 'shop_none_box'">
			<div class="title flex" style="justify-content: space-between; align-items: center">
				<h3 class="carTitle">已选媒介: {{ CarList.length }}</h3>
				<el-button v-show="CarList.length != 0" type="primary" @click="empty()">清空已选媒介</el-button>
				<span class="close" @click="shopShow = false"></span>
			</div>
			<ul class="listbox" v-show="CarList.length != 0">
				<li v-for="(item, index) in CarList" :key="index">
					<!-- <img :src="item.logo" alt="" /> -->
					<div class="flex over_text">
						<td_remark :text="item.title" />
					</div>
					<p class="price">￥{{ item.price.toFixed(2) }}</p>
					<span class="deleteIcon" @click.stop="deleteFn(item)"></span>
				</li>
			</ul>
			<el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
			<div class="bottom_box">
				<div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
					<p style="font-size: 1.4rem; color: #999">合计：</p>
					<p class="totalMoney">
						￥<span>{{ totalMoney ? totalMoney : '0.00' }}</span>
					</p>
				</div>
				<button class="put_but" @click="putFn">立即投放</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import media_screen from '@/components/media_screen'
import { media_screen_ } from '@/util/media_screen_'
import { checkAll } from '@/util/checkAll'
export default {
	computed: {
		...mapState([]),
		totalMoney: function () {
			let n = 0;
			this.CarList.forEach((item, index) => {
				if (this.CarList[index].check) {
					n += this.CarList[index].price;
				}
			})
			return n.toFixed(2);
		}
	},
	components: {
		media_screen,
	},
	mounted() {
		this.user_info = localStorage.getItem('user_info')
	},
	watch: {
		tableData: {
			handler(newValue) {
				if (this.tableData.length > 0) {
					let count = 0
					for (var i = 0; i < this.tableData.length; i++) {
						if (this.tableData[i].check == true) {
							count++;
						}
					}
					if (count == this.tableData.length) {
						this.Allcheck = true
					} else {
						this.Allcheck = false
					}
				}
			},
			deep: true,
		}

	},
	data() {
		return {
			user_info: {},
			/*数据表格*/
			tableData: [],
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
			Allcheck: false,
			fullscreenLoading: false,
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		onPitch(index) {
			// 飞入购物车动画
			this.tableData[index].check = !this.tableData[index].check
			if (this.tableData[index].check) {
				const x = event.clientX - 20
				const y = event.clientY - 20
				this.createBall(x, y)
				this.shopShow = true
				// this.CarList.push(this.tableData[index])
				this.CarList.push(this.tableData[index])
				/*------------------------------------------------------ */
			} else {
				this.tableData.forEach((item, index) => {
					this.CarList.forEach((el, i) => {
						if (!this.tableData[index].check && this.CarList[i].id == this.tableData[index].id) {
							this.CarList.splice(i, 1)

						}
					})
				})
			}
		},
		createBall(left, top) {
			const bar = document.createElement('ball')
			bar.style.position = 'fixed'
			bar.style.left = left + 'px'
			bar.style.top = top + 'px'
			bar.style.width = '20px'
			bar.style.height = '20px'
			bar.style.borderRadius = '50%'
			bar.style.backgroundColor = 'red'
			bar.style.transition =
				'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
			document.body.appendChild(bar)
			setTimeout(() => {
				const x = document.body.clientWidth * 1.5
				const y = document.body.clientHeight * 1 - 500
				bar.style.top = y + 'px'
				console.log(y)
				bar.style.left = x + 'px'
			}, 0)
			bar.ontransitionend = function () {
				this.remove()
			}
		},
		// 清空购物车
		empty() {
			this.$confirm('是否清空已选媒介?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.fullscreenLoading = true
					// setTimeout(() => {
					this.CarList = []
					this.shopShow = false
					// this.toggleSelection()
					this.tableData.forEach((item, index) => {
						if (item.check) {
							return (item.check = false)
						}
					})
					this.fullscreenLoading = false
					// }, 500)
					this.$message({
						type: 'success',
						message: '已清空已选媒介',
					})
				})
				.catch(() => {
					this.$message({
						type: 'warning',
						message: '已删除',
					})
				})
		},
		// 全选 反选
		checkSwitch() {
			this.Allcheck = !this.Allcheck
			this.CarList = checkAll(this.Allcheck, this.tableData, this.CarList)
			if (this.Allcheck) {
				this.shopShow = true
			}
		},
		// 获取列表数据
		get_list() {
			let data = media_screen_(this.$store.state.media_screen_data.date_form)
			data.page = this.page
			data.limit = 20
			this.fullscreenLoading = true
			this.curlGet('/api/medium/list', data).then(res => {
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData.forEach((item, index) => {
						this.$set(this.tableData[index], 'check', false)
						if (this.CarList.length != 0) {
							this.CarList.forEach((el, i) => {
								if (this.tableData[index].id == this.CarList[i].id) {
									this.tableData[index].check = true
								}
							})
						}
					})
					this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
				}
				this.fullscreenLoading = false
			})
		},
		// 删除
		deleteFn(item) {
			this.CarList.forEach((el, index) => {
				if (el.id == item.id) {

					return this.CarList.splice(index, 1)
				}
			})
			this.tableData.forEach((el, index) => {
				if (el.id == item.id) {
					return el.check = false
				}
			})

		},
		// 立即投放
		putFn() {
			let user_info = JSON.parse(localStorage.getItem('user_info')) || {}
			if (this.CarList.length == 0) {
				return this.$message({
					type: 'warning',
					message: '请选择媒介',
				})
			}
			if (Object.keys(user_info).length === 0) {
				this.$store.commit('login_tab', true)
				return this.$message({
					type: 'warning',
					message: '请登录',
				})

			}
			this.$store.commit('shopPush', this.CarList)
			this.$store.commit('SETACTIVEMENU', '/user/SetMenu/fill?id=7')
			this.$router.push({
				path: '/user/SetMenu/fill?id=7',
			})
		},
		// 收藏
		collectGet(item) {
			if (!this.user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
					offset: 90,
				})
			} else {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							message: '收藏成功',
							type: 'success'
						})
						this.get_list()
					}
				})
			}
		},
		// 取消收藏
		collectClose(item) {
			this.$confirm('是否取消收藏?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '取消收藏成功!'
						});
						this.get_list()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Media';
@import '@/scss/overText'
</style>